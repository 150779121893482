import React from 'react';

const LinkedInFooter = ({
	width = '32',
	height = '32',
	className = '',
}) => (
	<svg width={width} height={height} className={className} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0_1818:2074)">
			<path d="M6.48972 4.00006C5.89556 3.9962 5.31363 4.16886 4.81773 4.49615C4.32182 4.82344 3.93427 5.29061 3.70421 5.83844C3.47416 6.38626 3.41196 6.99007 3.5255 7.57329C3.63905 8.15651 3.92322 8.69288 4.34199 9.11439C4.76076 9.5359 5.29527 9.82357 5.87774 9.94091C6.46021 10.0583 7.0644 10 7.61372 9.77352C8.16303 9.54704 8.63272 9.16254 8.96324 8.66878C9.29375 8.17502 9.4702 7.59423 9.47022 7.00006C9.47195 6.20726 9.15911 5.44615 8.60034 4.88372C8.04157 4.3213 7.28251 4.0035 6.48972 4.00006Z" fill="#0A242D" />
			<path d="M22.1086 10.5011C20.4045 10.4776 18.7522 11.0876 17.4721 12.2127V10.9998H11.5034V27.9998H17.4734V19.1004C17.4734 17.034 18.7834 15.9529 20.0759 15.9529C20.3865 15.9517 20.6937 16.0162 20.9775 16.1423C21.2613 16.2684 21.5152 16.4531 21.7224 16.6844C22.2729 17.3652 22.5507 18.2263 22.5017 19.1004V27.9998H28.4897V17.3689C28.4897 13.8855 26.8042 11.4607 23.8657 10.7176C23.2912 10.5731 22.701 10.5004 22.1086 10.5011Z" fill="#0A242D" />
			<path d="M9.48975 11H3.48975V28H9.48975V11Z" fill="#0A242D" />
			<path d="M7.98975 19.0352H4.98975V27.9999H7.98975V19.0352Z" fill="#D3D3C7" />
			<path d="M15.9731 19.0352H13.0034V27.9999H15.9731V19.0352Z" fill="#D3D3C7" />
			<path d="M26.9898 19.0352H24.0015V27.9999H26.9898V19.0352Z" fill="#D3D3C7" />
			<path d="M23.498 12.172C23.0437 12.0578 22.577 12.0005 22.1086 12.0013C20.7846 11.9783 19.4985 12.4439 18.496 13.3091L15.9721 15.6663V12.5H13.0034V19.0353H15.979C16.0109 16.0608 18.102 14.4531 20.0756 14.4531C20.5956 14.4507 21.1103 14.5585 21.5856 14.7695C22.0609 14.9804 22.4861 15.2897 22.8332 15.6769C23.6224 16.6143 24.0367 17.8106 23.9965 19.0353H26.9897V17.3691C26.9897 15.7579 26.5361 12.94 23.498 12.172Z" fill="white" />
			<path d="M6.48978 8.5C7.30744 8.5 7.97028 7.82843 7.97028 7C7.97028 6.17157 7.30744 5.5 6.48978 5.5C5.67212 5.5 5.00928 6.17157 5.00928 7C5.00928 7.82843 5.67212 8.5 6.48978 8.5Z" fill="white" />
			<path d="M7.98975 12.5H4.98975V19.0353H7.98975V12.5Z" fill="white" />
		</g>
		<defs>
			<clipPath id="clip0_1818:2074">
				<rect width="25" height="24" fill="white" transform="translate(3.48975 4)" />
			</clipPath>
		</defs>
	</svg>

);

export default LinkedInFooter;
